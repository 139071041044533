.admin-sidebar {
  max-width: 280px;
  min-width: 280px;
  min-height: 100vh;
  background-color: #1f5e72;
  z-index: 100;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  &.admin {
    justify-content: flex-start;
  }
  .title {
    margin: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  .button {
    margin-top: 20px;
  }
}
