.primary-button-component {
  &.center {
    display: flex;
    justify-content: center;
  }
  &.rounded {
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      height: 43px;
      width: 45px;
    }
  }
  &.disbaledColor {
    button:disabled,
    button[disabled] {
      background-color: #faac32;
      opacity: 1;
    }
  }

  .btn {
    z-index: 89;
    cursor: pointer;
    padding: 4px 20px;
    background-color: #faac32;
    position: relative;
    color: black;
    border-radius: 100px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: -0.02em;
    border-color: transparent;
    border: none;
    span {
      white-space: nowrap;
    }
    .children {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      .small-loader-component {
        margin-right: 5px;
      }
      .arrowDown {
        margin-right: 10px;
        path {
          fill: #024d43;
        }
      }
      .email-icon {
        width: 20px;
        width: 20px;
        path {
          fill: #024d43;
        }
      }
    }
    svg {
      path {
        stroke: #024d43;
        fill: #024d43;
      }
    }

    &.primaryShade {
      background: rgba(var(--primary-light), 0.4);
      &:hover {
        background: rgba(var(--primary-light), 0.4) !important;
        opacity: 1;
      }
      &:active,
      &:focus {
        background: rgba(var(--primary-light), 0.4) !important;
        color: black !important;
      }
    }
    &.no-radius {
      border-radius: 8px;
      min-width: 87px;
    }
    &.redOrange {
      background-color: #ffb0a8;
    }
    &.small {
      font-size: 14px !important;
      padding: 1px 20px !important;
    }
    &.gray {
      background-color: #dfdfdf;
      color: black;
      &:hover {
        background-color: #dfdfdf;
        opacity: 1;
      }
      &:active,
      &:focus {
        background-color: #dfdfdf !important;
        color: black !important;
      }
    }
    &:hover {
      background-color: rgb(133, 189, 80);
      opacity: 0.7;
      color: black !important;
    }
    &:active,
    &:focus {
      color: black !important;
      background-color: rgb(133, 189, 80) !important;
    }

    &:disabled {
      opacity: 0.8;
      background-color: #d8d7d7d2;
      color: #545454;
      cursor: default !important;
    }
  }

  &.full {
    width: 100%;
    .btn {
      border-radius: 0;
      width: 100% !important;
    }
  }
  .skip {
    padding: 7px 30px;
    background-color: #d3d2d2;
    color: #5b6569;
    border-radius: 100px;
    font-weight: 500;
    font-size: 20px;
    border-color: transparent;
  }
  .btn[disabled] {
    pointer-events: none;
  }

  @media screen and (max-width: 768px) {
    .btn {
      white-space: nowrap;
      font-size: 14px !important;
      padding: 1px 20px !important;
    }
  }
  svg {
    width: 15px;
  }

  &.utility {
    .btn {
      font-weight: 600;
      padding: 2px 14px;
      text-decoration: none;
      border-radius: 9px;
      font-size: 16px;
      color: #7b7979;
      border: 1px solid #d3d2d2;
      background: #f7f7f7;
      margin: 0 2px;
      svg {
        margin-left: 5px;
        path {
          stroke: transparent;
        }
      }
    }
  }
}
